.menu {
  height: 100%;

  &_logo {
    display: flex;
    justify-content: center;
    height: 8rem;
    margin-top: 2rem;

    & img {
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  &_title {
    color: $secondary_color;
    font-size: 3rem;

    @include size_M {
      font-size: 1.75rem;
    }

    margin: 3rem auto;
  }

  &_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 6rem 0;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 2rem;
      border-bottom: 2px solid $secondary_color;
      color: $secondary_color;
      width: 320px;
      height: 320px;
      cursor: pointer;
      opacity: 1;
      transition: 250ms ease-in-out;

      &_image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin: -1rem;

        img {
          max-width: 60%;
          max-height: 60%;
        }
      }

      h2 {
        text-align: center;
        letter-spacing: 0.5rem;
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: row;
    color: $secondary_color;
    font-size: 1.25rem;
    gap: 0.5rem;
    margin: 0 auto;
    text-align: center;
    align-items: flex-start;
    justify-content: space-between;

    @include size_S {
      flex-direction: column;
      align-items: center;
    }

    p {
      width: max-content;
      font-size: inherit;
      margin: 0;
    }

    a {
      width: max-content;
    }

    &_left,
    &_right {
      gap: 0.5rem;
      display: flex;
      flex-direction: column;

      @include size_S {
        align-items: center;
      }
    }

    &_right {
      display: flex;
      align-items: flex-end;
    }
  }
}
